import { TeamBettingParameterStandingFlat } from "../../../types/groupTeamStanding"
import { Tabs } from "antd"
import { GroupStandingOld } from "@components/Competition/GroupStandingOld"
const { TabPane } = Tabs
import React, { useEffect, useState } from "react"
import { NewTitleBanner } from "@components/Banners/NewTitleBanner"
import homeTeamStats from "@assets/images/title-banner/home-team-stats.png"
import { GroupAPI } from "../../../apis/GroupAPI"
import { CompetitionTabs } from "@components/Competition/CompetitionTabs"
import { CalciocomConfigHomeLocalizedData } from "../../../types/calciocomConfigs"
import { Tabs as Tab } from "@components/UI/Tabs"

export function GroupStandingsWithCompetition({
  competitionCalciocomSlug,
  seasonCalciocomSlug,
  teamStandings: initialTeamStandings,
  homeTeamId = null,
  awayTeamId = null,
  headerTitle = null,
  homeData,
}: {
  competitionCalciocomSlug: string | null
  seasonCalciocomSlug: string | null
  teamStandings: TeamBettingParameterStandingFlat[]
  homeTeamId?: number | null
  awayTeamId?: number | null
  headerTitle?: string | null
  homeData: CalciocomConfigHomeLocalizedData[]
}) {
  const [teamStandings, setTeamStandings] = useState<TeamBettingParameterStandingFlat[]>(initialTeamStandings)
  const [changed, setChanged] = useState<boolean>(false)
  const [currentHomeData, setCurrentHomeData] = useState<CalciocomConfigHomeLocalizedData>(homeData[0])

  useEffect(() => {
    if (currentHomeData.competitionSeason && changed) {
      GroupAPI.getTeamBettingParameterStandingsFrontend(currentHomeData.group!.id).then((data) => {
        setTeamStandings(data)
      })
    }
  }, [currentHomeData, changed])

  const overTStandings = teamStandings.filter(
    (teamStanding) =>
      (teamStanding.parameter.code.includes("OVER1T") || teamStanding.parameter.code.includes("OVER2T")) &&
      !teamStanding.parameter.code.startsWith("T") &&
      !teamStanding.parameter.code.startsWith("COVER") &&
      !teamStanding.parameter.code.startsWith("CAROVER"),
  )
  const multiStandings = teamStandings.filter((teamStanding) => teamStanding.parameter.code.includes("MULTI"))

  const overStandings = teamStandings.filter(
    (teamStanding) =>
      teamStanding.parameter.code.includes("OVER") &&
      !teamStanding.parameter.code.includes("OVER1T") &&
      !teamStanding.parameter.code.includes("OVER2T") &&
      !teamStanding.parameter.code.startsWith("T") &&
      !teamStanding.parameter.code.startsWith("COVER") &&
      !teamStanding.parameter.code.startsWith("CAROVER"),
  )

  const otherStandings = teamStandings.filter(
    (teamStanding) =>
      !teamStanding.parameter.code.includes("OVER") &&
      !teamStanding.parameter.code.includes("MULTI") &&
      teamStanding.parameter.code !== "PNT" &&
      !teamStanding.parameter.code.startsWith("VIT") &&
      !teamStanding.parameter.code.startsWith("PAR") &&
      !teamStanding.parameter.code.startsWith("SCO") &&
      !teamStanding.parameter.code.startsWith("FGOL") &&
      !teamStanding.parameter.code.startsWith("MV") &&
      !teamStanding.parameter.code.startsWith("MVITV") &&
      !teamStanding.parameter.code.startsWith("MSCOV") &&
      !teamStanding.parameter.code.startsWith("LGOL") &&
      !teamStanding.parameter.code.startsWith("MV") &&
      !["FGOLX", "LGOLX", "CSHTX", "FGOL1TX", "FGOL2TX", "BOTHGOLX", "EVENX"].includes(teamStanding.parameter.code),
  )

  const resultStandings = teamStandings.filter(
    (teamStanding) =>
      teamStanding.parameter.code.startsWith("VIT") ||
      (teamStanding.parameter.code.startsWith("PAR") &&
        !["PAR00X", "PAR11X", "PARX"].includes(teamStanding.parameter.code)) ||
      teamStanding.parameter.code.startsWith("SCO"),
  )

  const goalStandings = teamStandings.filter((teamStanding) =>
    ["FGOLX", "LGOLX", "CSHTX", "BOTHGOLX", "EVENX"].includes(teamStanding.parameter.code),
  )

  const mv4Standings = teamStandings.filter((teamStanding) =>
    ["PARX", "MV1X", "MV2X", "MV3X"].includes(teamStanding.parameter.code),
  )

  const mv10Standings = teamStandings.filter((teamStanding) =>
    [
      "MVITV1X",
      "MVITV2X",
      "MVITV3X",
      "MVITV4X",
      "MSCOV1X",
      "MSCOV2X",
      "MSCOV3X",
      "MSCOV4X",
      "PAR00X",
      "PAR11X",
    ].includes(teamStanding.parameter.code),
  )

  const minuteFirstGoalStandings = teamStandings.filter((teamStanding) =>
    ["FGOL1TX", "FGOL2TX", "FGOLH1T0X", "FGOLH1T15X", "FGOLH1T30X", "FGOLH2T0X", "FGOLH2T15X", "FGOLH2T30X"].includes(
      teamStanding.parameter.code,
    ),
  )
  const minuteLastGoalStandings = teamStandings.filter((teamStanding) =>
    ["LGOL1TX", "LGOL2TX", "LGOLH1T0X", "LGOLH1T15X", "LGOLH1T30X", "LGOLH2T0X", "LGOLH2T15X", "LGOLH2T30X"].includes(
      teamStanding.parameter.code,
    ),
  )
  //"COVER35X", "COVER45X", "COVER55X", "COVER65X",
  const totalCornerStandings = teamStandings.filter((teamStanding) =>
    ["COVER35X", "COVER45X", "COVER55X", "COVER65X"].includes(teamStanding.parameter.code),
  )

  const cornerOverStandings = teamStandings.filter((teamStanding) => teamStanding.parameter.code.startsWith("TCOVER"))

  //CAROVER
  const cardOverStandings = teamStandings.filter((teamStanding) => teamStanding.parameter.code.startsWith("CAROVER"))

  const [overRadioValue, setOverRadioValue] = useState<string | undefined>(overStandings?.[0]?.parameter?.code)
  const [overTValue, setOverTValue] = useState<string | undefined>(overTStandings?.[0]?.parameter?.code)
  const [multiValue, setMultiValue] = useState<string | undefined>(multiStandings?.[0]?.parameter?.code)
  const [otherRadioValue, setOtherRadioValue] = useState<string | undefined>(otherStandings?.[0]?.parameter?.code)
  const [resultRadioValue, setResultRadioValue] = useState<string | undefined>(resultStandings?.[0]?.parameter?.code)
  const [goalRadioValue, setGoalRadioValue] = useState<string | undefined>(goalStandings?.[0]?.parameter?.code)
  const [mv4Value, setMv4Value] = useState<string | undefined>(mv4Standings?.[0]?.parameter?.code)
  const [mv10Value, setMv10Value] = useState<string | undefined>(mv10Standings?.[0]?.parameter?.code)
  const [minuteFirstGoalValue, setMinuteFirstGoalValue] = useState<string | undefined>(
    minuteFirstGoalStandings?.[0]?.parameter?.code,
  )
  const [minuteLastGoalValue, setMinuteLastGoalValue] = useState<string | undefined>(
    minuteLastGoalStandings?.[0]?.parameter?.code,
  )
  const [totCornerValue, setTotCornerValue] = useState<string | undefined>(totalCornerStandings?.[0]?.parameter?.code)

  const [overCornerValue, setOverCornerValue] = useState<string | undefined>(cornerOverStandings?.[0]?.parameter?.code)

  const [cardOverValue, setCardOverValue] = useState<string | undefined>(cardOverStandings?.[0]?.parameter?.code)

  const isMobile = (typeof window !== "undefined" && window?.matchMedia("(max-width: 600px)")?.matches) || false

  return (
    <div className="hs-standing">
      <NewTitleBanner
        icon={homeTeamStats}
        title={headerTitle ? headerTitle : `Statistiche per scommesse`}
        col={{ lg: 12 }}
      />
      <CompetitionTabs
        className="newCompetitionTabs"
        competitions={homeData.map((h) => h.competition)}
        selectedCompetitionId={currentHomeData.competition.id}
        onChange={(competitionId) => {
          const newCurrentHomeData = homeData.find((h) => h.competition.id === competitionId)
          if (newCurrentHomeData) {
            setCurrentHomeData(newCurrentHomeData)
            setChanged(true)
          }
        }}
        col={{ lg: 12 }}
      />

      {teamStandings.length > 0 ? (
        <Tabs
          defaultActiveKey={"1"}
          centered
          tabPosition={isMobile ? "top" : "left"}
          tabBarGutter={5}
          type={isMobile ? "line" : "card"}
        >
          {overStandings.length > 0 && (
            <TabPane tab={"Over"} key={"1"}>
              <Tab
                className={"group-standing-tab-new"}
                options={overStandings.map((o) => {
                  return { label: o.parameter.name.replace("Over", ""), value: o.parameter.code }
                })}
                onChange={(selectedId) => {
                  setOverRadioValue(selectedId as string)
                }}
                selectedId={overRadioValue}
              />
              <GroupStandingOld
                competitionCalciocomSlug={competitionCalciocomSlug}
                seasonCalciocomSlug={seasonCalciocomSlug}
                teamStandings={overStandings.find((o) => o.parameter.code === overRadioValue)?.standing || []}
                homeTeamId={homeTeamId}
                awayTeamId={awayTeamId}
                unitMeasurement={
                  overStandings.find((o) => o.parameter.code === overRadioValue)?.parameter?.unitMeasurement
                }
                enableTeamLink={false}
              />
            </TabPane>
          )}
          {overTStandings.length > 0 && (
            <TabPane tab={"Over 1T/2T"} key={"2"}>
              <Tab
                className={"group-standing-tab-new"}
                options={overTStandings.map((o) => {
                  return { label: o.parameter.name.replace("Over", " - "), value: o.parameter.code }
                })}
                onChange={(selectedId) => {
                  setOverTValue(selectedId as string)
                }}
                selectedId={overTValue}
              />
              <GroupStandingOld
                competitionCalciocomSlug={competitionCalciocomSlug}
                seasonCalciocomSlug={seasonCalciocomSlug}
                teamStandings={overTStandings.find((o) => o.parameter.code === overTValue)?.standing || []}
                homeTeamId={homeTeamId}
                awayTeamId={awayTeamId}
                unitMeasurement={
                  overTStandings.find((o) => o.parameter.code === overTValue)?.parameter?.unitMeasurement
                }
                enableTeamLink={false}
              />
            </TabPane>
          )}
          {multiStandings.length > 0 && (
            <TabPane tab={"Multigol"} key={"3"}>
              <Tab
                className={"group-standing-tab-new"}
                options={multiStandings.map((o) => {
                  return { label: o.parameter.name.replace("Multigol", ""), value: o.parameter.code }
                })}
                onChange={(selectedId) => {
                  setMultiValue(selectedId as string)
                }}
                selectedId={multiValue}
              />
              <GroupStandingOld
                competitionCalciocomSlug={competitionCalciocomSlug}
                seasonCalciocomSlug={seasonCalciocomSlug}
                teamStandings={multiStandings.find((o) => o.parameter.code === multiValue)?.standing || []}
                homeTeamId={homeTeamId}
                awayTeamId={awayTeamId}
                unitMeasurement={
                  multiStandings.find((o) => o.parameter.code === multiValue)?.parameter?.unitMeasurement
                }
                enableTeamLink={false}
              />
            </TabPane>
          )}
          {goalStandings.length > 0 && (
            <TabPane tab={"Gol"} key={"4"}>
              <Tab
                className={"group-standing-tab-new"}
                options={goalStandings.map((o) => {
                  return {
                    label: o.parameter.name.replace(/primo tempo/g, "1T").replace(/secondo tempo/g, "2T"),
                    value: o.parameter.code,
                  }
                })}
                onChange={(selectedId) => {
                  setGoalRadioValue(selectedId as string)
                }}
                selectedId={goalRadioValue}
              />
              <GroupStandingOld
                competitionCalciocomSlug={competitionCalciocomSlug}
                seasonCalciocomSlug={seasonCalciocomSlug}
                teamStandings={goalStandings.find((o) => o.parameter.code === goalRadioValue)?.standing || []}
                homeTeamId={homeTeamId}
                awayTeamId={awayTeamId}
                unitMeasurement={
                  goalStandings.find((o) => o.parameter.code === goalRadioValue)?.parameter?.unitMeasurement
                }
                enableTeamLink={false}
              />
            </TabPane>
          )}
          {resultStandings.length > 0 && (
            <TabPane tab={"Primo tempo / Finale"} key={"5"}>
              <Tab
                className={"group-standing-tab-new"}
                options={resultStandings.map((o) => {
                  return {
                    label: o.parameter.name
                      .replace(/Vittoria/g, "V")
                      .replace(/Vantaggio/g, "V")
                      .replace(/Svantaggio/g, "S")
                      .replace(/Pareggio/g, "P")
                      .replace(/Sconfitta/g, "S"),
                    value: o.parameter.code,
                  }
                })}
                onChange={(selectedId) => {
                  setResultRadioValue(selectedId as string)
                }}
                selectedId={resultRadioValue}
              />
              <GroupStandingOld
                competitionCalciocomSlug={competitionCalciocomSlug}
                seasonCalciocomSlug={seasonCalciocomSlug}
                teamStandings={resultStandings.find((o) => o.parameter.code === resultRadioValue)?.standing || []}
                homeTeamId={homeTeamId}
                awayTeamId={awayTeamId}
                unitMeasurement={
                  resultStandings.find((o) => o.parameter.code === resultRadioValue)?.parameter?.unitMeasurement
                }
                enableTeamLink={false}
              />
            </TabPane>
          )}
          {mv4Standings.length > 0 && (
            <TabPane tab={"Margine V4"} key={"6"}>
              <Tab
                className={"group-standing-tab-new"}
                options={mv4Standings.map((o) => {
                  return { label: o.parameter.name, value: o.parameter.code }
                })}
                onChange={(selectedId) => {
                  setMv4Value(selectedId as string)
                }}
                selectedId={mv4Value}
              />
              <GroupStandingOld
                competitionCalciocomSlug={competitionCalciocomSlug}
                seasonCalciocomSlug={seasonCalciocomSlug}
                teamStandings={mv4Standings.find((o) => o.parameter.code === mv4Value)?.standing || []}
                homeTeamId={homeTeamId}
                awayTeamId={awayTeamId}
                unitMeasurement={mv4Standings.find((o) => o.parameter.code === mv4Value)?.parameter?.unitMeasurement}
                enableTeamLink={false}
              />
            </TabPane>
          )}
          {mv10Standings.length > 0 && (
            <TabPane tab={"Margine V10"} key={"7"}>
              <Tab
                className={"group-standing-tab-new"}
                options={mv10Standings.map((o) => {
                  return {
                    label: o.parameter.name
                      .replace("Vittoria", "V")
                      .replace("Sconfitta", "S")
                      .replace("Pareggio", "P")
                      .replace("ed entrambe gol", "e GG"),
                    value: o.parameter.code,
                  }
                })}
                onChange={(selectedId) => {
                  setMv10Value(selectedId as string)
                }}
                selectedId={mv10Value}
              />
              <GroupStandingOld
                competitionCalciocomSlug={competitionCalciocomSlug}
                seasonCalciocomSlug={seasonCalciocomSlug}
                teamStandings={mv10Standings.find((o) => o.parameter.code === mv10Value)?.standing || []}
                homeTeamId={homeTeamId}
                awayTeamId={awayTeamId}
                unitMeasurement={mv10Standings.find((o) => o.parameter.code === mv10Value)?.parameter?.unitMeasurement}
                enableTeamLink={false}
              />
            </TabPane>
          )}
          {minuteFirstGoalStandings.length > 0 && (
            <TabPane tab={"Min. primo gol"} key={"8"}>
              <Tab
                className={"group-standing-tab-new"}
                options={minuteFirstGoalStandings.map((o) => {
                  return {
                    label: o.parameter.name.replace("Primo tempo", "1T").replace("Secondo tempo", "2T"),
                    value: o.parameter.code,
                  }
                })}
                onChange={(selectedId) => {
                  setMinuteFirstGoalValue(selectedId as string)
                }}
                selectedId={minuteFirstGoalValue}
              />
              <GroupStandingOld
                competitionCalciocomSlug={competitionCalciocomSlug}
                seasonCalciocomSlug={seasonCalciocomSlug}
                teamStandings={
                  minuteFirstGoalStandings.find((o) => o.parameter.code === minuteFirstGoalValue)?.standing || []
                }
                homeTeamId={homeTeamId}
                awayTeamId={awayTeamId}
                unitMeasurement={
                  minuteFirstGoalStandings.find((o) => o.parameter.code === minuteFirstGoalValue)?.parameter
                    ?.unitMeasurement
                }
                enableTeamLink={false}
              />
            </TabPane>
          )}
          {minuteLastGoalStandings.length > 0 && (
            <TabPane tab={"Min. ultimo gol"} key={"9"}>
              <Tab
                className={"group-standing-tab-new"}
                options={minuteLastGoalStandings.map((o) => {
                  return {
                    label: o.parameter.name.replace("Primo tempo", "1T").replace("Secondo tempo", "2T"),
                    value: o.parameter.code,
                  }
                })}
                onChange={(selectedId) => {
                  setMinuteLastGoalValue(selectedId as string)
                }}
                selectedId={minuteLastGoalValue}
              />
              <GroupStandingOld
                competitionCalciocomSlug={competitionCalciocomSlug}
                seasonCalciocomSlug={seasonCalciocomSlug}
                teamStandings={
                  minuteLastGoalStandings.find((o) => o.parameter.code === minuteLastGoalValue)?.standing || []
                }
                homeTeamId={homeTeamId}
                awayTeamId={awayTeamId}
                unitMeasurement={
                  minuteLastGoalStandings.find((o) => o.parameter.code === minuteLastGoalValue)?.parameter
                    ?.unitMeasurement
                }
                enableTeamLink={false}
              />
            </TabPane>
          )}
          {totalCornerStandings.length > 0 && (
            <TabPane tab={"Corner"} key={"10"}>
              <Tab
                className={"group-standing-tab-new"}
                options={totalCornerStandings.map((o) => {
                  return {
                    label: o.parameter.name.replace("Primo tempo", "1T").replace("Secondo tempo", "2T"),
                    value: o.parameter.code,
                  }
                })}
                onChange={(selectedId) => {
                  setTotCornerValue(selectedId as string)
                }}
                selectedId={totCornerValue}
              />
              <GroupStandingOld
                competitionCalciocomSlug={competitionCalciocomSlug}
                seasonCalciocomSlug={seasonCalciocomSlug}
                teamStandings={totalCornerStandings.find((o) => o.parameter.code === totCornerValue)?.standing || []}
                homeTeamId={homeTeamId}
                awayTeamId={awayTeamId}
                unitMeasurement={
                  totalCornerStandings.find((o) => o.parameter.code === totCornerValue)?.parameter?.unitMeasurement
                }
                enableTeamLink={false}
              />
            </TabPane>
          )}
          {cornerOverStandings.length > 0 && (
            <TabPane tab={"Over Corner"} key={"11"}>
              <Tab
                className={"group-standing-tab-new"}
                options={cornerOverStandings.map((o) => {
                  return {
                    label: o.parameter.name,
                    value: o.parameter.code,
                  }
                })}
                onChange={(selectedId) => {
                  setOverCornerValue(selectedId as string)
                }}
                selectedId={overCornerValue}
              />
              <GroupStandingOld
                competitionCalciocomSlug={competitionCalciocomSlug}
                seasonCalciocomSlug={seasonCalciocomSlug}
                teamStandings={cornerOverStandings.find((o) => o.parameter.code === overCornerValue)?.standing || []}
                homeTeamId={homeTeamId}
                awayTeamId={awayTeamId}
                unitMeasurement={
                  cornerOverStandings.find((o) => o.parameter.code === overCornerValue)?.parameter?.unitMeasurement
                }
                enableTeamLink={false}
              />
            </TabPane>
          )}
          {otherStandings.length > 0 && (
            <TabPane tab={"Cartellini"} key={"12"}>
              <Tab
                className={"group-standing-tab-new"}
                options={otherStandings.map((o) => {
                  return { label: o.parameter.name, value: o.parameter.code }
                })}
                onChange={(selectedId) => {
                  setOtherRadioValue(selectedId as string)
                }}
                selectedId={otherRadioValue}
              />
              <GroupStandingOld
                competitionCalciocomSlug={competitionCalciocomSlug}
                seasonCalciocomSlug={seasonCalciocomSlug}
                teamStandings={otherStandings.find((o) => o.parameter.code === otherRadioValue)?.standing || []}
                homeTeamId={homeTeamId}
                awayTeamId={awayTeamId}
                unitMeasurement={
                  otherStandings.find((o) => o.parameter.code === otherRadioValue)?.parameter?.unitMeasurement
                }
                enableTeamLink={false}
              />
            </TabPane>
          )}
          {cardOverStandings.length > 0 && (
            <TabPane tab={"Over Cartellini"} key={"13"}>
              <Tab
                className={"group-standing-tab-new"}
                options={cardOverStandings.map((o) => {
                  return { label: o.parameter.name, value: o.parameter.code }
                })}
                onChange={(selectedId) => {
                  setCardOverValue(selectedId as string)
                }}
                selectedId={cardOverValue}
              />
              <GroupStandingOld
                competitionCalciocomSlug={competitionCalciocomSlug}
                seasonCalciocomSlug={seasonCalciocomSlug}
                teamStandings={cardOverStandings.find((o) => o.parameter.code === cardOverValue)?.standing || []}
                homeTeamId={homeTeamId}
                awayTeamId={awayTeamId}
                unitMeasurement={
                  cardOverStandings.find((o) => o.parameter.code === cardOverValue)?.parameter?.unitMeasurement
                }
                enableTeamLink={false}
              />
            </TabPane>
          )}
        </Tabs>
      ) : (
        <span>Classifica non disponibile</span>
      )}
    </div>
  )
}
