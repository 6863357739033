import React from "react"
import { LocalizedCompetition } from "../../../types/competition"
import { ColProps } from "react-bootstrap/Col"
import { Tabs } from "@components/UI/Tabs"

export function CompetitionTabs({
  competitions,
  selectedCompetitionId,
  onChange = () => {
    return
  },
  col,
  theme = "light",
  className,
}: {
  competitions: LocalizedCompetition[]
  selectedCompetitionId?: number
  onChange?: (selectedCompetitionId: number) => void
  col?: ColProps
  theme?: "dark" | "light"
  className?: string
}) {
  return (
    <Tabs
      className={`competition-tabs-cc ${className ? className : ""} ${theme}`}
      selectedId={selectedCompetitionId || competitions[0]?.id}
      options={competitions.map((competition) => {
        return {
          value: competition.id,
          label: competition.name,
          image: competition.country?.image?.path || competition.image?.path,
        }
      })}
      onChange={(competitionId) => {
        onChange(competitionId as number)
      }}
      col={col}
    />
  )
}
